import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer, Form, Input, Space, Radio } from "antd";
import { createOffer } from "../../store/offer/offerReducer";

const ChildDrawer = ({ open, close, productClass, title }) => {
  const offer = useSelector((state) => state.offer.offer);
  const dispatch = useDispatch();
  const [productionClass, setValue] = useState();
  const [form] = Form.useForm();

  const addProductionClass = () => {
    dispatch(createOffer({ ...offer, productionClass }));
    closeDrawer();
  };

  const closeDrawer = () => {
    form.resetFields();
    setValue();
    close();
  };

  const onFormValuesChange = (_, allValues) => {
    setValue({ ...productionClass, ...allValues });
  };

  return (
    <Drawer
      title={title}
      width={320}
      closable={false}
      onClose={close}
      open={open}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          remember: true,
        }}
        onValuesChange={onFormValuesChange}
      >
        {productClass.quality.map((item) => {
          return (
            <Form.Item key={item.key} name={item.key} label={item.value}>
              {item.type ? (
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder={item.value}
                />
              ) : (
                <Radio.Group name={item.key}>
                  {item.choice.map((value) => {
                    return (
                      <Radio key={value.key} value={value.value}>
                        {value.value}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              )}
            </Form.Item>
          );
        })}
      </Form>
      <Space>
        <Button htmlType="reset" onClick={closeDrawer}>
          Отмена
        </Button>
        <Button onClick={addProductionClass} type="primary">
          Сохранить
        </Button>
      </Space>
    </Drawer>
  );
};

export default ChildDrawer;
