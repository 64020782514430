import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";
import { getAuthToken } from "../../api/functions";

const backendURL = process.env.REACT_APP_API;

const initialState = {
  loading: false,
  offers: null,
  offer: null,
  error: null,
  success: false,
};

const offerSlice = createSlice({
  initialState,
  name: "offers",
  reducers: {
    getOffers: (state, action) => {
      state.offers = action.payload;
    },
    createOffer(state, action) {
      state.offer = action.payload;
    },
    deleteOffer(state, action) {},
    editOffer(state, action) {},
    showOffer(state, action) {},
    fetchStarted(state, action) {
      // отправка запроса
      state.loading = true;
      state.error = null;
    },
    fetchSuccess(state, action) {
      // получение ответа
      state.loading = false;
      state.error = null;
      state.offers = action.payload;
    },
    fetchFailure(state, action) {
      // произошла ошибка
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getOffers, createOffer, deleteOffer, editOffer, showOffer } =
  offerSlice.actions;

const { fetchStarted, fetchSuccess, fetchFailure } = offerSlice.actions; // генераторы действий

export const getOffersList = () => async (dispatch) => {
  dispatch(fetchStarted());
  try {
    const response = await axios.get(`${backendURL}/offers`, {
      headers: { Authorization: `Bearer ${getAuthToken()}` },
    });

    dispatch(fetchSuccess(response.data));
  } catch (error) {
    dispatch(fetchFailure(error.message)); // произошла ошибка
  }
};

export const saveOffer = (data) => async (dispatch) => {
  dispatch(fetchStarted());

  try {
    const response = await axios.post(`${backendURL}/offers`, {
      ...data,
      headers: { Authorization: `Bearer ${getAuthToken()}` },
    });

    dispatch(fetchSuccess(response.data));
  } catch (error) {
    dispatch(fetchFailure(error.message)); // произошла ошибка
  }
};

export default offerSlice.reducer;
