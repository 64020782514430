import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { selectors } from "../../store/selectors/selectors";
import { Table, Tag, Button, Flex } from "antd";
import DrawerItem from "../../components/Drawer/drawer";

import { getOffersList } from "../../store/offer/offerReducer";

import { findStatus } from "../../helpers/functions";

import "./offers.scss";

const columns = [
  {
    dataIndex: "productionName",
    key: "name",
    width: "100%",
    hidden: document.body.offsetWidth > 1600,
    render: (_, record) => (
      <>
        <div className="panel__small">
          <span>Наименование:</span>
          <span>{record.productionName}</span>
        </div>
        <div className="panel__small">
          <span>Цена за тонну:</span>
          <span>{record.price}</span>
        </div>
        <div className="panel__small">
          <span>Адрес:</span>
          <span>{`${record.region}, ${record.district}, ${record.city}`}</span>
        </div>
        <div className="panel__small">
          <span>Контакт:</span>
          <span>{`${record.sellersPhone}, ${record.sellersName}`}</span>
        </div>
        <div className="panel__small">
          <span>Статус:</span>
          <Tag
            color={findStatus(record.status).color}
            key={findStatus(record.status).status}
          >
            {findStatus(record.status).status}
          </Tag>
        </div>
      </>
    ),
  },
  {
    title: "Наименование",
    dataIndex: "productionName",
    key: "name",
    render: (text) => <a>{text}</a>,
    width: "284px",
    responsive: ["xxl"],
  },
  {
    title: "Цена за тонну",
    dataIndex: "price",
    key: "price",
    responsive: ["xxl"],
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
    width: "337px",
    responsive: ["xxl"],
    render: (_, record) =>
      `${record.region}, ${record.district}, ${record.city}`,
  },
  {
    title: "Контакт",
    dataIndex: "sellersPhone",
    key: "contact",
    responsive: ["xxl"],
    render: (_, record) => (
      <>
        <div>{record.sellersPhone}</div>
        <div>{record.sellersName}</div>
      </>
    ),
  },
  {
    title: "Статус",
    key: "status",
    dataIndex: "status",
    width: "200px",
    responsive: ["xxl"],
    render: (status) => {
      const statusColors = findStatus(status);
      return (
        <Tag color={statusColors.color} key={statusColors.status}>
          {statusColors.status}
        </Tag>
      );
    },
  },
  // {
  //   title: " ",
  //   dataIndex: "action",
  //   key: "action",
  //   responsive: ["xxl"],
  //   render: (text) => <a href="/">{text}</a>,
  // },
];

const newColumns = columns.filter((item) => !item.hidden);

const Offers = () => {
  const offer = useSelector((state) => state.offer.offers);
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    dispatch(getOffersList());
  }, [dispatch]);

  return (
    <>
      <div className="panel__box offers">
        <DrawerItem
          open={openDrawer}
          close={() => onClose()}
          title="Новое предложение"
        />
        <Flex
          justify={"space-between"}
          align={"center"}
          className="panel__title"
        >
          <span>Предложение</span>
          <Button type="primary" onClick={showDrawer}>
            Создать
          </Button>
        </Flex>
        <div className="panel__table">
          <Table
            showHeader={document.body.offsetWidth > 1600}
            columns={newColumns}
            dataSource={offer}
          />
        </div>
      </div>
    </>
  );
};

export default Offers;
