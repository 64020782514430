import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Table, Tag, Flex } from "antd";

import { getOffersList } from "../../store/offer/offerReducer";
import { findStatus } from "../../helpers/functions";
import "./panel.scss";

const columns = [
  {
    dataIndex: "productionName",
    key: "name",
    width: "100%",
    hidden: document.body.offsetWidth > 1600,
    render: (_, record) => (
      <>
        <div className="panel__small">
          <span>Наименование:</span>
          <span>{record.productionName}</span>
        </div>
        <div className="panel__small">
          <span>Цена за тонну:</span>
          <span>{record.price}</span>
        </div>
        <div className="panel__small">
          <span>Адрес:</span>
          <span>{`${record.region}, ${record.district}, ${record.city}`}</span>
        </div>
        <div className="panel__small">
          <span>Контакт:</span>
          <span>{`${record.sellersPhone}, ${record.sellersName}`}</span>
        </div>
        <div className="panel__small">
          <span>Статус:</span>
          <Tag
            color={findStatus(record.status).color}
            key={findStatus(record.status).status}
          >
            {findStatus(record.status).status}
          </Tag>
        </div>
      </>
    ),
  },
  {
    title: "Наименование",
    dataIndex: "productionName",
    key: "name",
    render: (text) => <a>{text}</a>,
    width: "284px",
    responsive: ["xxl"],
  },
  {
    title: "Цена за тонну",
    dataIndex: "price",
    key: "price",
    responsive: ["xxl"],
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
    width: "337px",
    responsive: ["xxl"],
    render: (_, record) =>
      `${record.region}, ${record.district}, ${record.city}`,
  },
  {
    title: "Контакт",
    dataIndex: "contact",
    key: "contact",
    responsive: ["xxl"],
    render: (_, record) => (
      <>
        <div>{record.sellersPhone}</div>
        <div>{record.sellersName}</div>
      </>
    ),
  },
  {
    title: "Статус",
    key: "status",
    dataIndex: "status",
    width: "200px",
    responsive: ["xxl"],
    render: (_, { status }) => {
      const statusColors = findStatus(status);
      return (
        <Tag color={statusColors.color} key={statusColors.status}>
          {statusColors.status}
        </Tag>
      );
    },
  },
  // {
  //   title: " ",
  //   dataIndex: "action",
  //   key: "action",
  //   render: (text) => <a href="/">{text}</a>,
  // },
];

const newColumns = columns.filter((item) => !item.hidden);

const Panel = () => {
  const offers = useSelector((state) => state.offer.offers);
  const dispatch = useDispatch();

  const offerData = offers?.slice(0, 5);

  useEffect(() => {
    dispatch(getOffersList());
  }, [dispatch]);

  return (
    offers && (
      <div className="panel__box">
        <Flex className="panel__title">
          <span>Предложение</span>
          <NavLink to="/offers" className="panel__link">
            Смотреть все
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5.29289 5.29289C5.68342 5.68342 5.68342 6.31658 5.29289 6.70711L1 11"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        </Flex>

        <div className="panel__table">
          <Table
            showHeader={document.body.offsetWidth > 1600}
            columns={newColumns}
            dataSource={offerData}
            pagination={false}
          />
        </div>
      </div>
    )
  );
};

export default Panel;
