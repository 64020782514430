import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "./authApi";

const initialState = {
  loading: false,
  user: null,
  token: null,
  error: null,
  success: false,
};

const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    logout: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    // login user
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log(payload.data);
      state.loading = false;
      state.user = payload.data.getUser;
      state.token = payload.data.token;
      state.success = true; // login successful
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default userSlice.reducer;

export const { logout, setUser } = userSlice.actions;
