import { Route, Routes, useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import MainIndex from "./view/Index/index";
import Panel from "./view/Panel/panel";
// import Requests from "./view/Requests/requests";
import Offers from "./view/Offers/offers";
import Main from "./components/Main/main";
import Auth from "./view/Auth/auth";
import Users from "./view/Users/users";
import ErrorBoundary from "./view/Error/error";

import { checkAuth } from "./api/functions";

import "./assets/style/style.scss";

const App = () => {
  let location = useLocation();

  return (
    <div className="app">
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: "#00541c",
              algorithm: true, // Enable algorithm
            },
          },
        }}
      >
        <Routes>
          <Route path="/" element={<MainIndex />} />
          {!checkAuth() && location.pathname !== "/" ? (
            <Route path="/auth" element={<Auth />} />
          ) : (
            <Route element={<Main />} errorElement={<ErrorBoundary />}>
              <Route path="panel" element={<Panel />} />
              {/* <Route path="requests" element={<Requests />} /> */}
              <Route path="offers" element={<Offers />} />
              <Route path="requests/:requestsId" />
              <Route path="offers/:offersId" />
              <Route path="settings" />
              <Route path="users" element={<Users />} />
            </Route>
          )}
        </Routes>
      </ConfigProvider>
    </div>
  );
};

export default App;
