import { createSlice } from "@reduxjs/toolkit";
import { getRequests } from "./requestApi";

const initialState = {
  loading: false,
  request: null,
  error: null,
  success: false,
};

const requestSlice = createSlice({
  initialState,
  name: "requestSlice",
  reducers: {
    setRequest: (state, action) => {
      state.request = action.payload;
    },
  },
  extraReducers: {
    // get request
    [getRequests.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getRequests.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.request = payload.data;
      state.success = true; // requests successful
    },
    [getRequests.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default requestSlice.reducer;

export const { logout, setUser } = requestSlice.actions;
