import Icon from "@ant-design/icons";

const MainSvg = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 2.5C1 1.94772 1.44772 1.5 2 1.5H6C6.55228 1.5 7 1.94772 7 2.5V6.5C7 7.05228 6.55228 7.5 6 7.5H2C1.44772 7.5 1 7.05228 1 6.5V2.5Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 12.5C1 11.9477 1.44772 11.5 2 11.5H6C6.55228 11.5 7 11.9477 7 12.5V16.5C7 17.0523 6.55228 17.5 6 17.5H2C1.44772 17.5 1 17.0523 1 16.5V12.5Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 2.5C11 1.94772 11.4477 1.5 12 1.5H16C16.5523 1.5 17 1.94772 17 2.5V6.5C17 7.05228 16.5523 7.5 16 7.5H12C11.4477 7.5 11 7.05228 11 6.5V2.5Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 12.5C11 11.9477 11.4477 11.5 12 11.5H16C16.5523 11.5 17 11.9477 17 12.5V16.5C17 17.0523 16.5523 17.5 16 17.5H12C11.4477 17.5 11 17.0523 11 16.5V12.5Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const RequestSvg = () => (
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 11.5L6.29289 6.20711C6.68342 5.81658 7.31658 5.81658 7.70711 6.20711L10.2929 8.79289C10.6834 9.18342 11.3166 9.18342 11.7071 8.79289L19 1.5M19 1.5H14M19 1.5V6.5"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const OfferSvg = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 14.5L18 6.5L21 14.5C20.13 15.15 19.08 15.5 18 15.5C16.92 15.5 15.87 15.15 15 14.5Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 14.5L4 6.5L7 14.5C6.13 15.15 5.08 15.5 4 15.5C2.92 15.5 1.87 15.15 1 14.5Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 19.5H16"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 1.5V19.5"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 5.5H4C6 5.5 9 4.5 11 3.5C13 4.5 16 5.5 18 5.5H20"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const SettingsSvg = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 9.60998V15.38C3 17.5 3 17.5 5 18.85L10.5 22.03C11.33 22.51 12.68 22.51 13.5 22.03L19 18.85C21 17.5 21 17.5 21 15.39V9.60998C21 7.49998 21 7.49999 19 6.14999L13.5 2.96999C12.68 2.48999 11.33 2.48999 10.5 2.96999L5 6.14999C3 7.49999 3 7.49998 3 9.60998Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UsersSvg = () => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15.5V14.5C1 12.2909 2.79086 10.5 5 10.5H9C11.2091 10.5 13 12.2909 13 14.5V15.5M13 7.5C14.6569 7.5 16 6.15685 16 4.5C16 2.84315 14.6569 1.5 13 1.5M19 15.5V14.5C19 12.2909 17.2091 10.5 15 10.5H14.5M10 4.5C10 6.15685 8.65685 7.5 7 7.5C5.34315 7.5 4 6.15685 4 4.5C4 2.84315 5.34315 1.5 7 1.5C8.65685 1.5 10 2.84315 10 4.5Z"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const OutSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 17L21 12L16 7"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 12H9"
      stroke="#00541C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const MainIcon = (props) => <Icon component={MainSvg} {...props} />;
export const RequestIcon = (props) => (
  <Icon component={RequestSvg} {...props} />
);
export const OfferIcon = (props) => <Icon component={OfferSvg} {...props} />;
export const UsersIcon = (props) => <Icon component={UsersSvg} {...props} />;
export const SettingsIcon = (props) => (
  <Icon component={SettingsSvg} {...props} />
);
export const OutIcon = (props) => <Icon component={OutSvg} {...props} />;
