export const findStatus = (status) => {
  let color;
  switch (status) {
    case "Опубликовано":
      color = "green";
      break;
    case "В работе":
      color = "orange";
      break;
    case "Уточнить информацию":
      color = "blue";
      break;
    case "Продано":
      color = "cyan";
      break;
    case "Не опубликовано":
      color = "volcano";
      break;
    default:
      color = "magenta";
  }
  return { status, color };
};
