import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { setLocalAuthData, setSessionAuthData } from "../../api/functions";

const backendURL = process.env.REACT_APP_API;

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password, remember }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${backendURL}/auth/login`, {
        email,
        password,
      });

      if (remember) {
        setLocalAuthData(response.data);
      } else {
        setSessionAuthData(response.data);
      }

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
