/*
	Устанавливает auth_data в localStorage
*/
export const setLocalAuthData = (authData) => {
  if (!authData || !authData.token) return;

  localStorage.setItem("auth_data", JSON.stringify(authData.token));
};

/*
	Устанавливает auth_data в sessionStorage
*/
export const setSessionAuthData = (authData) => {
  if (!authData || !authData.token) return;

  sessionStorage.setItem("auth_data", JSON.stringify(authData.token));
};

/*
      Возвращает auth_data из localStorage
  */
export const getLocalAuthData = () => {
  return JSON.parse(localStorage.getItem("auth_data"));
};

/*
      Возвращает auth_user из localStorage
  */
export const getLocalAuthUser = () => {
  return JSON.parse(localStorage.getItem("auth_user"));
};

/*
      проверяет авторизован ли пользователь
      Возвращает true, если авторизован и false, если не авторизован
  */
export const checkAuth = () => {
  return !!localStorage.getItem("auth_data");
};

/*
      проверяет является ли пользователь администратором
      Возвращает true, если является и false, если не является
  */
export const checkAdmin = (user) => {
  if (user && user.role === "admin") {
    return true;
  } else {
    return false;
  }
};

/*
      Возвращает token из LocalStorage
  */
export const getAuthToken = () => {
  return localStorage.getItem("auth_data")
    ? JSON.parse(localStorage.getItem("auth_data"))
    : null;
};

/*
      Выход из авторизованного профиля
      Отчищает localStorage и обнуляет State
  */
export const logout = (setDataFunc, setUserFunc) => {
  localStorage.removeItem("auth_data");
  localStorage.removeItem("auth_user");
  setDataFunc({
    token: null,
    expiresIn: null,
  });
  setUserFunc({
    id: null,
    login: null,
    first_name: null,
    last_name: null,
    role: null,
    photo: null,
  });
};
