import logo from "../../assets/img/pic/Logo.svg";

import "./index.scss";

const MainIndex = () => {
  return (
    <div className="main-index">
      <div className="main-index__wrapper">
        <div className="main-index__box">
          <div className="main-index__logo">
            <img src={logo} alt="Логотип. Агросервис" className="img__fliud" />
          </div>
          <div className="main-index__text">
            Здесь небольшое пояснение на несколько слов о сервисе
          </div>
          <div className="main-index__sub">Скоро открытие регистрации</div>
        </div>
      </div>
    </div>
  );
};

export default MainIndex;
