import { useState, useRef, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Divider,
  Radio,
  Checkbox,
  Flex,
} from "antd";

import ChildDrawer from "./childDrawer";
import { saveOffer, createOffer } from "../../store/offer/offerReducer";

import "./drawer.scss";

const { Option } = Select;

const product = [
  {
    key: "wheat3",
    name: "Пшеница 3 класс",
    quality: [
      {
        key: "protein",
        value: "Протеин не менее",
        type: "text",
      },
      {
        key: "gluten",
        value: "Клейковина %",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "Nature",
        value: "Натура не менее",
        type: "text",
      },
      {
        key: "IDK",
        value: "ИДК",
        type: "text",
      },
      {
        key: "CHP",
        value: "ЧП",
        type: "text",
      },
      {
        key: "Sprouted",
        value: "Проросшие До",
        type: "text",
      },
      {
        key: "bug",
        value: "Клоп До",
        type: "text",
      },
      {
        key: "mix",
        value: "Зерно примесь не более %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "wheat4",
    name: "Пшеница 4 класс",
    quality: [
      {
        key: "protein",
        value: "Протеин не менее",
        type: "text",
      },
      {
        key: "gluten",
        value: "Клейковина %",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "Nature",
        value: "Натура не менее",
        type: "text",
      },
      {
        key: "IDK",
        value: "ИДК",
        type: "text",
      },
      {
        key: "CHP",
        value: "ЧП",
        type: "text",
      },
      {
        key: "Sprouted",
        value: "Проросшие До",
        type: "text",
      },
      {
        key: "bug",
        value: "Клоп До",
        type: "text",
      },
      {
        key: "mix",
        value: "Зерно примесь не более %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "wheat5",
    name: "Пшеница 5 класс",
    quality: [
      {
        key: "protein",
        value: "Протеин не менее",
        type: "text",
      },
      {
        key: "gluten",
        value: "Клейковина %",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "Nature",
        value: "Натура не менее",
        type: "text",
      },
      {
        key: "IDK",
        value: "ИДК",
        type: "text",
      },
      {
        key: "CHP",
        value: "ЧП",
        type: "text",
      },
      {
        key: "Sprouted",
        value: "Проросшие До",
        type: "text",
      },
      {
        key: "bug",
        value: "Клоп До",
        type: "text",
      },
      {
        key: "mix",
        value: "Зерно примесь не более %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "fodderBarley",
    name: "Ячмень Кормовой",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "Nature",
        value: "Натура не менее",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "maltingBarley",
    name: "Ячмень пивоваренный",
    quality: [
      {
        key: "selection",
        value: "Селекция",
        choice: [
          {
            key: "ru",
            value: "Отечественная",
          },
          {
            key: "fn",
            value: "Зарубежная",
          },
        ],
      },
      {
        key: "size",
        value: "Крупность не менее",
        type: "text",
      },
      {
        key: "live",
        value: "Жизнеспособность не менее",
        type: "text",
      },
      {
        key: "Nature",
        value: "Натура не менее",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "corn",
    name: "Кукуруза",
    quality: [
      {
        key: "break",
        value: "Битые не более",
        type: "text",
      },
      {
        key: "damage",
        value: "Поврежденных не более %",
        type: "text",
      },
      {
        key: "ambrosia",
        value: "Амброзия, шт/кг не более",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "mix",
        value: "Зерно примесь не более %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "fodderPeas",
    name: "Горох кормовой",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "Sprouted",
        value: "Проросшие зерна",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "sunflower",
    name: "Подсолнечник",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "oil",
        value: "Масличность не менее",
        type: "text",
      },
      {
        key: "acidNumber",
        value: "Кислотное число",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "peaFood",
    name: "Горох Продовольственный",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "Sprouted",
        value: "Проросшие зерна",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "break",
        value: "Битые не более",
        type: "text",
      },
      {
        key: "damage",
        value: "Изъеденные до %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "linen",
    name: "Лён",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "oil",
        value: "Масличность не менее",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "acidNumber",
        value: "Кислотное число, мг Кон/г",
        type: "text",
      },
      {
        key: "oilMix",
        value: "Масличная примесь не более",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "rape",
    name: "Рапс",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "oil",
        value: "Масличность не менее",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "acidNumber",
        value: "Кислотное число, мг Кон/г",
        type: "text",
      },
      {
        key: "oilMix",
        value: "Масличная примесь не более",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "camelina",
    name: "Рыжик",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "oil",
        value: "Масличность не менее",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "acidNumber",
        value: "Кислотное число, мг Кон/г",
        type: "text",
      },
      {
        key: "oilMix",
        value: "Масличная примесь не более",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "soy",
    name: "Соя",
    quality: [
      {
        key: "protein",
        value: "Протеин сырой",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "acidNumber",
        value: "Кислотное число, мг Кон/г",
        type: "text",
      },
      {
        key: "oilMix",
        value: "Масличная примесь не более",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "oats",
    name: "Овёс",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "Nature",
        value: "Натура не менее",
        type: "text",
      },
      {
        key: "mix",
        value: "Зерновая примесь до %",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "wildOats",
        value: "Овсюг до %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "rye",
    name: "Рожь",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "quality",
        value: "Класс",
        choice: [
          {
            key: "1",
            value: "1",
          },
          {
            key: "2",
            value: "2",
          },
          {
            key: "3",
            value: "3",
          },
        ],
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "Nature",
        value: "Натура не менее",
        type: "text",
      },
      {
        key: "CHP",
        value: "ЧП",
        type: "text",
      },
      {
        key: "mix",
        value: "Зерновая примесь до %",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "amaranth",
    name: "Амарант",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        type: "text",
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
  {
    key: "buckwheat",
    name: "Гречиха",
    quality: [
      {
        key: "sort",
        value: "Сорт",
        choice: [
          {
            key: "1",
            value: "1",
          },
          {
            key: "2",
            value: "2",
          },
          {
            key: "3",
            value: "3",
          },
        ],
      },
      {
        key: "wet",
        value: "Влажность",
        type: "text",
      },
      {
        key: "goodCore",
        value: "Доброкачественное ядро не менее %",
        type: "text",
      },
      {
        key: "damageCore",
        value: "Колотые ядра не более %",
        type: "text",
      },
      {
        key: "dirtyCore",
        value: "Нешелушенные ядра не более %",
        type: "text",
      },
      {
        key: "badMix",
        value: "Сорная примесь %",
        type: "text",
      },
      {
        key: "infect",
        value: "Заражённость",
        choice: [
          {
            key: "yes",
            value: "Есть",
          },
          {
            key: "no",
            value: "Нет",
          },
        ],
      },
      {
        key: "infectText",
        value: "Укажите заражённость",
        type: "text",
      },
      {
        key: "comment",
        value: "Комментарии",
        type: "text",
      },
    ],
  },
];

const DrawerItem = ({ open, close, title }) => {
  const offer = useSelector((state) => state.offer.offer);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { productionName } = form.getFieldsValue();

  // const addItem = (e) => {
  //   e.preventDefault();
  //   setItems([...product, { name } || { name: `New item ${index++}` }]);
  //   setName("");
  //   setTimeout(() => {
  //     inputRef.current?.focus();
  //   }, 0);
  // };

  const checkProduct = () => {
    return (
      (product.find((item) => item.name === productionName) && true) || false
    );
  };

  const searchAddress = async (event) => {
    const index = event.target.value;

    if (index.length === 6) {
      const address = await fetch(
        `https://www.postindexapi.ru/json/${event.target.value.slice(0, -3)}/${
          event.target.value
        }.json`
      )
        .then((res) => res.json())
        .then((data) => data);
      dispatch(
        createOffer({
          ...offer,
          index: index,
          region: address.Region,
          city: address.City,
          district: address.Area || address.City,
        })
      );
    }
  };

  const resetDrawer = () => {
    form.resetFields();
    close();
  };

  const saveOfferItem = () => {
    dispatch(saveOffer(offer));
    resetDrawer();
  };

  const onFormValuesChange = (_, allValues) => {
    if (allValues.documents && allValues.documents.includes("Нет документов")) {
      allValues.documents = ["Нет документов"];
    }

    if (
      allValues.productionPriceItem &&
      allValues.productionPriceItem !== "₽"
    ) {
      allValues.productionNDS = "";
    }

    dispatch(createOffer({ ...allValues }));
  };

  useEffect(() => {
    form.setFieldsValue({ ...offer });
  }, [form, offer]);

  return (
    <>
      <Drawer
        title={title}
        width={650}
        onClose={close}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            sellersPhone: "+7",
            remember: true,
          }}
          onValuesChange={onFormValuesChange}
        >
          <Form.Item name="productionName" label="Наименование">
            <Select
              style={{
                width: "100%",
              }}
              placeholder="Наименование"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {/* <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                    }}
                  >
                    <Input
                      placeholder="Введите наименование"
                      ref={inputRef}
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addItem}
                    >
                      Добавить
                    </Button>
                  </Space> */}
                </>
              )}
              options={product.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item name="productionClass" label="Укажите показатель качества">
            {checkProduct() ? (
              <>
                <Button type="primary" onClick={() => setOpenDrawer(true)}>
                  Указать
                </Button>
                <ChildDrawer
                  productClass={product.find(
                    (item) => item.name === productionName
                  )}
                  open={openDrawer}
                  close={() => setOpenDrawer(false)}
                  title="Показатели качества"
                />
              </>
            ) : (
              <Input
                style={{
                  width: "100%",
                }}
                placeholder="Пожалуйста, укажите показатели качества"
              />
            )}
          </Form.Item>
          <Form.Item name="productionValue" label="Объем (тонн)">
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Введите объем"
            />
          </Form.Item>

          <Form.Item name="productionDelivery" label="Условия поставки">
            <Select placeholder="Условия поставки">
              <Option value="Самовывоз с хозяйства">
                Самовывоз с хозяйства
              </Option>
              <Option value="Самовывоз с элеватора">
                Самовывоз с элеватора
              </Option>
              <Option value="Доставлю своим транспортом">
                Доставлю своим транспортом
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="price" label="Цена за тонну">
            <Input
              style={{
                width: "100%",
              }}
              placeholder="Введите цену"
            />
          </Form.Item>
          <Row>
            <Flex justify={"flex-start"} align={"flex-start"}>
              <Form.Item name="productionPriceItem">
                <Radio.Group>
                  <Row>
                    <Radio value={"₽"}>Рубли - ₽</Radio>
                  </Row>
                  <Row>
                    <Radio value={"$"}>Доллары - $</Radio>
                  </Row>
                  <Row>
                    <Radio value={"Наличные"}>Наличные</Radio>
                  </Row>
                </Radio.Group>
              </Form.Item>
              {offer?.productionPriceItem === "₽" && (
                <Form.Item name="productionNDS">
                  <Radio.Group>
                    <Radio value={"с НДС"}>с НДС</Radio>
                    <Radio value={"без НДС"}>без НДС</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
            </Flex>
          </Row>
          <Form.Item name="documents" label="Наличие документов">
            <Checkbox.Group
              style={{
                width: "100%",
              }}
            >
              <Row>
                <Col span={10}>
                  <Checkbox value="Карантинный сертификат">
                    Карантинный сертификат
                  </Checkbox>
                  <Checkbox value="Декларация кормовая">
                    Декларация кормовая
                  </Checkbox>
                </Col>

                <Col span={10}>
                  <Checkbox value="Декларация продовольственная">
                    Декларация продовольственная
                  </Checkbox>
                  <Checkbox value="Нет документов">Нет документов</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          {offer?.productionDelivery !== "Доставлю своим транспортом" && (
            <>
              <Form.Item name="howLoad" label="Погрузка">
                <Radio.Group
                  style={{
                    width: "100%",
                  }}
                >
                  <Row>
                    <Col span={10}>
                      <Radio value={"По норме"}>По норме</Radio>
                    </Col>
                    <Col span={10}>
                      <Radio value={"По договоренности"}>
                        По договоренности
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="productionWeight" label="Укажите наличие весов">
                <Radio.Group>
                  <Radio value={"60 тонн"}>60 тонн</Radio>
                  <Radio value={"80 тонн"}>80 тонн</Radio>
                  <Radio value={"100 тонн"}>100 тонн</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="loading" label="Способ погрузки">
                <Checkbox.Group
                  style={{
                    width: "100%",
                  }}
                >
                  <Row>
                    <Col span={10}>
                      <Checkbox value="Зерномет">Зерномет</Checkbox>
                    </Col>
                    <Col span={10}>
                      <Checkbox value="Маниту">Маниту</Checkbox>
                    </Col>
                    <Col span={10}>
                      <Checkbox value="Кун">Кун</Checkbox>
                    </Col>
                    <Col span={10}>
                      <Checkbox value="Амкодор">Амкодор</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item name="loadingSize" label="Объем погрузки в сутки">
                <Radio.Group>
                  <Radio value={"250 тонн"}>250 тонн</Radio>
                  <Radio value={"500 тонн"}>500 тонн</Radio>
                  <Radio value={"800 тонн"}>800 тонн</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}
          <Form.Item name="index" label="Индекс" onChange={searchAddress}>
            <Input placeholder="Введите индекс" />
          </Form.Item>
          <Form.Item name="region" label="Ваш регион">
            <Input
              placeholder={
                offer && offer.region ? offer.region : "Введите регион"
              }
              disabled={offer && !!offer.region}
            />
          </Form.Item>
          <Form.Item name="district" label="Ваш район">
            <Input
              placeholder={
                offer && offer.district ? offer.district : "Введите район"
              }
              disabled={offer && !!offer.district}
            />
          </Form.Item>
          <Form.Item name="city" label="Ваш населенный пункт">
            <Input
              placeholder={
                offer && offer.city ? offer.city : "Введите населенный пункт"
              }
              disabled={offer && !!offer.city}
            />
          </Form.Item>
          <Form.Item name="sellersName" label="Имя">
            <Input placeholder="Введите имя" />
          </Form.Item>
          <Form.Item name="sellersPhone" label="Телефон">
            <Input
              maxLength="12"
              count={{
                show: true,
                max: 12,
              }}
              placeholder="Введите телефон"
            />
          </Form.Item>
          <Form.Item name="comment" label="Комментарий">
            <Input.TextArea rows={4} placeholder="Добавьте комментарий" />
          </Form.Item>
        </Form>
        <Space>
          <Button onClick={resetDrawer}>Отменить</Button>
          <Button onClick={() => saveOfferItem()} type="primary">
            Отправить
          </Button>
        </Space>
      </Drawer>
    </>
  );
};

export default DrawerItem;
