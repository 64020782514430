import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authReducer";
import requestReducer from "./request/requestReducer";
import offerReducer from "./offer/offerReducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    request: requestReducer,
    offer: offerReducer,
  },
});

export default store;
