import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input } from "antd";

import { loginUser } from "../../store/auth/authApi";

import logo from "../../assets/img/pic/logo-green.svg";

import "./auth.scss";
import { useEffect } from "react";

const Auth = () => {
  const { loading, user, error, success } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (data) => {
    data.email = data.email.toLowerCase();
    dispatch(loginUser(data));
  };

  useEffect(() => {
    if (user) {
      navigate("/panel");
    }
  }, [navigate, user]);

  return (
    <div className="auth">
      <div className="auth__wrapper">
        <div className="auth__logo">
          <img src={logo} alt="" className="img-fluid" />
        </div>
        <div className="auth__title">Авторизация</div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите e-mail",
              },
            ]}
          >
            <Input placeholder="E-mail" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите пароль",
              },
            ]}
          >
            <Input.Password type="password" placeholder="Пароль" />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Запомнить</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Забыли свой пароль?
            </a>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button auth__button"
            >
              Войти
            </Button>
            {/* или <a href="">зарегистрироваться</a> */}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Auth;
