import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import HeaderPanel from "../layout/header";
import Sidebar from "../Sidebar/sidebar";

import "./main.scss";

const Main = () => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sidebar />
      <Layout className="main">
        <HeaderPanel />
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default Main;
