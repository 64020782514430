import { NavLink } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  MainIcon,
  RequestIcon,
  OfferIcon,
  UsersIcon,
  SettingsIcon,
} from "../../assets/style/icons/icons";

import logo from "../../assets/img/pic/logo-green.svg";
import profile from "../../assets/img/pic/profile.png";

import "./sidebar.scss";

const { Sider } = Layout;

const Sidebar = () => {
  const menu = [
    {
      path: "/panel",
      label: <NavLink to="/panel">Главная</NavLink>,
      key: "1",
      icon: <MainIcon />,
    },
    {
      path: "/requests",
      label: <NavLink to="/requests">Спрос</NavLink>,
      key: "2",
      icon: <RequestIcon />,
      disabled: true,
    },
    {
      path: "/offers",
      label: <NavLink to="/offers">Предложение</NavLink>,
      key: "3",
      icon: <OfferIcon />,
    },
    {
      path: "/settings",
      label: <NavLink to="/settings">Настройки</NavLink>,
      key: "4",
      icon: <SettingsIcon />,
      disabled: true,
    },
    {
      path: "/users",
      label: <NavLink to="/users">Пользователи</NavLink>,
      key: "5",
      icon: <UsersIcon />,
      disabled: true,
    },
  ];
  return (
    <Sider
      width="315px"
      className="sidebar"
      style={{
        background: "#ffffff",
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <a href="/" className="logo">
        <img src={logo} alt="" className="img-fluid" />
      </a>
      <div className="sidebar__wrapper">
        <div className="sidebar__box">
          <div className="sidebar__img">
            <img src={profile} alt="" className="img-fluid" />
          </div>
          <p className="sidebar__name">Иван Иванов</p>
          <p className="sidebar__prof">Оператор</p>
        </div>
        <Menu
          style={{ background: "#ffffff" }}
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={menu}
          className="sidebar-nav"
        />
      </div>
      <div className="sidebar__item">
        <button className="sidebar__exit">
          <i className="exit" />
          <span>Выход</span>
        </button>
      </div>
    </Sider>
  );
};

export default Sidebar;
